import { Container, Col } from "react-bootstrap";

import React from "react";
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStylesStyleD";
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faXTwitter } from "@fortawesome/free-brands-svg-icons"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"

const COLOR_TEXT_FOOTER_STYLE_D = "#F8F9FA" 

const FooterComponentStyleB = () => {
  return (
    <Box>

        <FooterContainer>
        
            <Row>
        
                <Column>

                    <Heading
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                        }}
                        >MyPlanner online&trade;
                    </Heading>

                    <FooterLink
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                        }}
                        >
                       Copyright &copy; 2024 MPM project
                    </FooterLink>

                    <FooterLink
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                        }}
                    >
                      WEB AGENCY
                    </FooterLink>

                    <FooterLink href="#"
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                            fontStyle: "italic"
                        }}
                        >
                        info@mpmproject.it
                    </FooterLink>

                </Column>

                <Column>
                    <Heading
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                        }}
                        >
                        About Us
                    </Heading>

                    <FooterLink href="https://www.mystudionline.it/"
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                            fontStyle: "italic"
                        }}
                        >    
                        sitiWeb <b>MyStudio</b> 
                    </FooterLink>

                    <FooterLink href="https://www.myideaonline.it/"
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                            fontStyle: "italic"
                        }}
                        >
                        sitiWeb <b>MyIdea</b> 
                    </FooterLink>

                    <FooterLink href="https://www.myshoponline.it/"
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                            fontStyle: "italic"
                        }}
                        >
                        ecommerce <b>MyShop</b> 
                    </FooterLink>

                </Column>               

                <Column>
    
                    <Heading
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                        }}
                        >
                        &nbsp;
                    </Heading>
                    
                    <FooterLink href="https://www.myplanneronline.it/"
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                            fontStyle: "italic"
                        }}
                        >
                        sitiWeb <b>MyPlanner</b> 
                    </FooterLink>

                    <FooterLink href="https://www.myplaceonline.it/"
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                            fontStyle: "italic"
                        }}
                        >
                        sitiWeb <b>MyPlace</b> 
                    </FooterLink>

                </Column>

                <Column>
                
                    <Heading
                        style={{
                            color: COLOR_TEXT_FOOTER_STYLE_D,
                        }}
                        >
                        Social Media
                    </Heading>

                    <row>

                        <FooterLink href="#">
                            <FontAwesomeIcon icon={faFacebook}  
                                        style={{
                                            marginLeft: "0px",
                                            color: "orange",
                                            height: "40px",
                                        }}
                            >
                            </FontAwesomeIcon>
                        </FooterLink>

                        <FooterLink href="#">
                            <FontAwesomeIcon icon={faInstagramSquare}  
                                        style={{
                                            marginLeft: "20px",
                                            color: "orange",
                                            height: "40px",
                                        }}
                            >
                            </FontAwesomeIcon>
                        </FooterLink>

                        <FooterLink href="#">
                            <FontAwesomeIcon icon={faTwitter}  
                                        style={{
                                            marginLeft: "20px",
                                            color: "orange",
                                            height: "40px",
                                        }}
                            >
                            </FontAwesomeIcon>
                        </FooterLink>

                        <FooterLink href="https://www.mpmproject.it"
                            style={{
                                color: COLOR_TEXT_FOOTER_STYLE_D,
                                fontStyle: "italic"
                            }}
                            >
                            www.mpmproject.it
                        </FooterLink>

                     </row>

                </Column>
            </Row>

        </FooterContainer>

    </Box>
  );
};


export default FooterComponentStyleB;
